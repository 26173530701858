var isPage = "";

var Cmt = {
	"top" : 0,
	"show" : 0
}
var cmts = [];
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body"),
			$floating = $("#block_floating");

		common.resize();
		common.nav();
		notify.init();
		//console.log($.cookie("depaaat_visited"));

		if($.cookie("depaaat_visited") === "visited"){
			$floating.addClass("disable");
		}else{
			$floating.addClass("active");
			$.cookie("depaaat_visited", "visited",{ path: "/" });
		}
		//
		if(isPage === "top"){
			g.init();
		}else if(isPage === "itemlist"){
			sort.init();
		}
		/*if($("li.g").length > 0){
			common.addGridClass();
		}*/
		common.scrlCmt();
		setTimeout(function(){
			$(document).on("touchmove scroll", function(){
				common.scrlCmt();
				if(!$floating.hasClass("disable")){
					$floating.addClass("disable");
				}
			});
		},200);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	addGridClass: function(){
		var even = true;
		$(".list_shop_items li").each(function(i){
			var $this = $(this);
			if(i%5 === 0){
				if(even){
					even = false;
				}else{
					even = true;
				}
			}else{
				if(even){
					if(i%2 === 0){
						$this.addClass("ml");
					}
				}else{
					if(i%2 === 1){
						$this.addClass("ml");
					}
				}
			}
		});
	},
	nav: function(){
		var $body = $("body"),
			$modal = $("div[data-item-modal]");
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		
		
		$(".sort h4 a").off().on("click", function(){
			var idx = $(".sort h4 a").index(this);
			$(".sort").eq(idx).toggleClass("hide");
			$(".accordion").eq(idx).animate({
				height: "toggle", opacity: "toggle"
			},400);
		});
		$(".tag_more a").off().on("click", function(){
			$(this).parent().remove();
			$(".list_tag li").removeClass("pc_only hide");
		});
		//グローバルメニュー
		var flag = true;

		$("#btn_menu").on("click", function(){
			var len = $("#top #news").length;
			var wrapperY = $("#wrapper").offset().top;
			if($body.hasClass("menu_category") && $body.hasClass("menu") && $body.hasClass("category_chosen")){
				$(".category_list").removeClass("active");
				$body.removeClass("category_chosen");
			}else if($body.hasClass("menu_category") && $body.hasClass("menu")){
				$body.removeClass("menu_category");
			}else if($body.hasClass("menu") && !$body.hasClass("menu_category")){
				$body.removeClass("menu");
			}else if($body.hasClass("modal_show_item")){
				var url 	= location.href;
				var prop 	= location.pathname;
				var propArry = prop.split("/");
				var url = "/" + propArry[propArry.length-2] + "/";
				page.push(url);
			}else{
				if(len > 0 && $(document).scrollTop() < wrapperY){
					$page.animate({
						scrollTop : wrapperY + "px"
					},{
						"duration" 	: 200,
						"easing" 	: ease,
						"complete"	: function(){
							if(flag){
								flag = false;
								openNav();
							}
						}
					});
				}else{
					openNav();
				}
				function openNav(){
					$body.addClass("menu");
					flag = true;
					$("li.nav_category a").off().on("click", function(){
						if(!$body.hasClass("menu_category")){
							$body.addClass("menu_category");
						}
					});
					$(".category_list_nav li a").off().on("click", function(){
						var idx = $(".category_list_nav li a").index(this);
						$body.addClass("category_chosen");
						$("#sp_category .category_list").eq(idx).addClass("active");
					});
				}
			}
		});
		//こだわり検索
		$(".btn_search").on("click", function(){
			if(!$body.hasClass("menu_search")){
				$body.addClass("menu_search");
				search.sort();
			}
		});
		//カテゴリオープン ---------------------------
		$("a.nav_category").on("click", function(){
			var _h = $("#category_inner").outerHeight();
			if($body.hasClass("open_category")){
				$("body").removeClass("open_category");
				$("#select_category").css({
					"height" : ""
				});
				$("#wrapper").css({
					"margin-top" : ""
				});
			}else{
				$("body").addClass("open_category");
				$("#select_category").css({
					"height" : _h + "px"
				});
				$("#wrapper").css({
					"margin-top" : _h + "px"
				});
			}
		});
		//ITEM DETAIL ---------------------------
		$(".select_size").not(".disable").on("click", function(){
			common.selectSize();
		});
		//page top ------------------------------
		$("a.btn_pagetop").off().on("click", function(){
			common.scrollToPosition(0);
		});
	},
	checkFooter: function(){
		var footerH = $("footer").outerHeight(),
			winH 	= $(window).height(),
			wrapH 	= $("#wrapper").outerHeight(),
			left 	= winH - wrapH - footerH,
			needFix = false,
			$footer = $("footer");
		if(isSmallScreen){
			if(left > 80){
				needFix = true;
			}
		}else{
			if(left > 100){
				needFix = true;
			}
		}
		if(needFix){
			if(!$footer.hasClass("fix")){
				$footer.addClass("fix");
			}
			$footer.css({
				"margin-top" : left + "px"
			});
		}else{
			if($footer.hasClass("fix")){
				$footer.removeClass("fix");
			}
			$footer.css({
				"margin-top" : ""
			});
		}
		
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width :960px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		//アイテム詳細
		common.checkFooter();
		if(isPage === "itemdetail" || $("body").hasClass("modal_show_item")){
			detailSlideshow.checkSlideNav();
		}
		/*
		リサイズ時にPCのバリエーション選択が開きっぱなしだったら閉じる
		*/
		if($("div[data-modal-size-pc]").length > 0){
			if($("div[data-modal-size-pc]").is(":visible")){
				$("div[data-modal-size-pc]").css({
					"display" : "none"
				});
			}
		}
		common.checkCommentPos();
		setTimeout(function(){
			common.checkCommentPos();
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkCommentPos: function(){
		cmts = [];
		if($(".cmt").length > 0){
			$(".list_items").each(function(i){
				$(this).find("li.g").each(function(j){
					var _c = object(Cmt);
					_c.top = Math.floor($(this).offset().top);
					_c.show = Math.floor(_c.top + $(window).height()/2);
					cmts.push(_c);
				});
			});
		}
	},
	scrollToPosition: (pos) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		var posY = pos;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	selectSize: function(){
		var $body = $("body");
		if(isSmallScreen){
			if(!$body.hasClass("show_modal_size")){
				$body.addClass("show_modal_size");
				selectFromList();
				$("#btn_close_size").off().on("click", function(){
					if($body.hasClass("show_modal_size")){
						$body.removeClass("show_modal_size");
					}
				});
			}
		}else{
			if($body.hasClass("show_modal_size")){
				$body.removeClass("show_modal_size");
			}
			$("a.select_size").eq(0).toggleClass("open");
			$("div[data-modal-size-pc]").animate({
				height: "toggle", opacity: "toggle"
			},400);
			selectFromList();
		}

		function selectFromList(){
			$(".size_content li a").not(".nostock").off().on("click", function(){
				var size 	= $(this).attr("data-color");
				var price 	= $(this).attr("data-price");
				var dataID 	= $(this).attr("data-id");
				var left 	= $(this).find(".stock").text();

				$("input.input_selected_item").val(dataID);
				$(".select_size").each(function(i){
					if($("p.select_var").is(":visible")){
						$(this).addClass("active");
					}
					$(this).find(".size_color").html(size);
					$(this).find(".price").html("&yen" + price + "");
					$(this).find(".stock").text(left);
				});
				if($body.hasClass("show_modal_size")){
					$body.removeClass("show_modal_size");
				}
				if($("button.btn_addtocart").hasClass("disable")){
					$("button.btn_addtocart").removeClass("disable");
				}
				if($("div[data-modal-size-pc]").is(":visible")){
					$("div[data-modal-size-pc]").animate({
						height: "toggle", opacity: "toggle"
					},400);
				}
			});
		}
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			$body = $("body");
	},
	scrlCmt: function(){
		var topD 	= $(document).scrollTop(),
			btmD 	= topD + $(window).height(),
			len 	= cmts.length,
			$body 	= $("body"),
			wrapperH = $("#wrapper").outerHeight(),
			winH 	= $(window).height(),
			docH 	= $(document).height();

		for(var i = 0; i < len; i++){
			if(i === len - 1){
				if(btmD >= cmts[i].show){
					active(i);
				}
			}else{
				
			}
			if(btmD >= cmts[i].show){
				active(i);
			}else{
				disable(i);
			}
		}
		if(btmD >= docH - 100){
			for(var j = 0; j < 5; j++){
				var num = len-j-1;
				active(num);
			}
		}
		function active(i){
			var $blk = $(".g").eq(i);
			//removeAnimation();
			if($blk.hasClass("d")){
				//removeotherAnimation(j);
				$blk.removeClass("d");
				setTimeout(function(){
					$blk.find(".bgcmt").removeClass("d");
				},50);
			}
		}
		function disable(i){
			var $blk = $(".g").eq(i);
			if(!$blk.hasClass("d")){
				//removeotherAnimation(j);
				$blk.find(".bgcmt").addClass("d");
				setTimeout(function(){
					$blk.addClass("d");
				},400);
			}
		}
		//only top
		if(isPage === "top" && $body.hasClass("hasnews")){
			if(topD >= 60){
				if(!$body.hasClass("fixnav")){
					$body.addClass("fixnav");
				}
			}else if(topD < 60){
				if($body.hasClass("fixnav")){
					$body.removeClass("fixnav");
				}
			}
		}else{
			if(topD > 0){
				if(!$body.hasClass("fixnav")){
					$body.addClass("fixnav");
				}
			}else if(topD < 1){
				if($body.hasClass("fixnav")){
					$body.removeClass("fixnav");
				}
			}
		}
		if(isPage === "top"){
			var heroH = $(".img_hero").offset().top + $(".img_hero figure").height();
			if(topD >= heroH - $("header").height()){
				if(!$body.hasClass("show_logo")){
					$body.addClass("show_logo");
				}
			}else{
				if($body.hasClass("show_logo")){
					$body.removeClass("show_logo");
				}
			}
		}
	}
}
//ITEM SORT -----------------------------
var sort = {
	flag: true,
	init: function(){
		this.nav();
	},
	nav: function(){
		var $movingTag = $(".moving_tag");
		$(".sort_tag li.select a").not(".more .disable").off().on("click", function(){
			if(sort.flag){
				sort.flag = false;
				var $this 			= $(this);
				var tagID 			= $(this).parent().attr("data-tag-id");
				var $lastSelected 	= $(".sort_tag li.selected").eq(0);
				var pos 			= [$(this).position().top, $(this).position().left];
				var posNext 		= [$lastSelected.position().top, $lastSelected.position().left];
				var tagClone 		= $this.clone();
				var tagClone02 		= $this.clone();

				$(".sort_tag ul li.selected").eq(0).html(tagClone);
				$(".sort_tag ul li.selected").eq(0).attr("data-selected-id", tagID)
				//中身をコピーしたあとにクリックした場所に初期値点を移動
				//console.log(pos);
				$movingTag.css({
					"top" : pos[0] + "px",
					"left" : pos[1] + "px"
				});
				//return false
				//移動させるためのlistにクリックした中身をコピー
				$movingTag.append(tagClone02);
				$lastSelected = $(".sort_tag li.selected:eq(0)");
				$lastSelected.removeClass("disable");
				pos = [$(this).position().top, $(this).position().left];
				$movingTag.css({
					"top" : pos[0] + "px",
					"left" : pos[1] + "px"
				});
				setTimeout(function(){
					//中身をコピーしたあとに該当箇所へ移動
					posNext 	= [$lastSelected.position().top, $lastSelected.position().left];
					//return false
					$movingTag.addClass("active");
					$movingTag.css({
						"top" : posNext[0] + "px",
						"left" : posNext[1] + "px"
					});
					$this.parent().addClass("disable");
					$movingTag.off().on(transitionend, function(){
						$movingTag.off();
						$movingTag.removeClass("active");
						setTimeout(function(){
							sort.afterMove();
							$lastSelected.addClass("active");
							$(".sort_tag ul").prepend('<li class="selected disable"></li>');
						},100);
					});
				},100);
			}
		});
	},
	afterMove: function($this,posNext){
		removeMoveTag();
		
		sort.flag = true;
		var $movingTag = $(".moving_tag");
		$(".sort_tag li.selected a").off().on("click", function(){
			var $this = $(this);
			if(sort.flag){
				sort.flag = false;
				var tagID 	= $this.parent().attr("data-selected-id");
				var $target = $('[data-tag-id="' + tagID + '"]');
				var startPos = [$this.position().top, $this.position().left],
					endPos 	= [$target.position().top, $target.position().left],
					cloneTag = $this.clone();
				//console.log(tagID,startPos,endPos);

				$movingTag.append(cloneTag);
				$movingTag.css({
					"top" : startPos[0] + "px",
					"left" : startPos[1] + "px"
				});
				$this.parent().remove();
				$movingTag.addClass("active").addClass("back");
				$target.addClass("haswidth");
				setTimeout(function(){
					endPos 	= [$target.position().top, $target.position().left]
					$movingTag.css({
						"top" : endPos[0] + "px",
						"left" : endPos[1] + "px"
					});
					//移動終了後
					$movingTag.off().on(transitionend, function(){
						$movingTag.off();
						$target.removeClass("disable haswidth");
						
						setTimeout(function(){
							removeMoveTag();
							$movingTag.removeClass("active back");

							sort.flag = true;
						},100);
					});
				},50);
			}
		});
		function removeMoveTag(){
			$(".moving_tag a").remove();
			$(".moving_tag").css({
				"top" : "",
				"left" : ""
			});
		}
	}
}
var notify = {
	init: function(){
		$(document).on("keyup", function(e){
			var k = e.keyCode;
			if(k === 38){
				//notify.show("");
			}
		})
	},
	show: function(text){
		var p = "topRight",
			t = "alert";
		noty({
			layout: p,
			text: text,
			type: t,
			timeout: 4000,
			progressBar: true,
			animation: {
				open: {height: 'toggle'},
				close: {height: 'toggle'},
				speed: 300 // opening & closing animation speed
			},
			closeWith: ['click']
		});
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});