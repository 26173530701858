var search = {
	sort: function(){
		var $body = $("body");
		var $search = $("#block_nav_search");
		$('#nav_choose_category li a[data-search="category"]').each(function(i){
			user.category.push("選択して下さい");
		});
		//スマホ -----------------------------------------------------
		$(".btn_close_search").off().on("click", function(){
			if($body.hasClass("menu_search") && $search.hasClass("nav_category_chosen")){
				//カテゴリ詳細 > カテゴリ選択
				$(".nav_in_category").removeClass("active");
				$search.removeClass();
			}else if($body.hasClass("menu_search")){
				var cls = $search.attr("class");
				if(cls != ""){
					$search.removeClass();
				}else{
					//初期状態
					$body.removeClass("menu_search");
				}
			}
		});
		//絞り込みカテゴリ選択
		var cat,catIndex;
		$("#nav_choose_category li a").off().on("click", function(){
			var $this = this;
			cat = $(this).attr("data-search");
			if(cat === "category"){
				catIndex = $('#nav_choose_category li a[data-search="category"]').index($this);
				$("#block_nav_search").addClass("nav_category_chosen");
				$(".nav_in_category").eq(catIndex).addClass("active");
			}else if(cat === "price"){

			}else if(cat === "genre"){

			}
			$search.addClass("active_" + cat);

		});
		$(".nav_choice ul li a").off().on("click", function(){
			$(this).parent().parent().find("a").removeClass("active");
			$(this).toggleClass("active");
			var target = $(this).attr("data-name");
			if(cat === "category"){
				user.category[catIndex] = target;
			}else if(cat === "price"){
				user.price = target;
			}else if(cat === "genre"){
				user.genre = target;
			}
			search.changeSort();
		});
		
		//PC ----------------------------------------------------------
	},
	changeSort: function(){
		//console.log(user);
		$("#nav_choose_category ul li").eq(0).find("dd").html(user.category);
		$("dd.category_from_list").each(function(i){
			$(this).html(user.category[i]);
		});
		$("#nav_choose_category ul li dd.chosen_price").html(user.price);
		$("#nav_choose_category ul li dd.chosen_genre").html(user.genre);
	}
}