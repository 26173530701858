var userAgent 	= window.navigator.userAgent.toLowerCase();
var appVersion 	= window.navigator.appVersion.toLowerCase();
var isLegacy 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1;
var isIE10 		= appVersion.indexOf("msie 10.0") > -1;
var isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1;
var isIE8over 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1 ||  appVersion.indexOf("msie 11.0") > -1;
var isIE 		= userAgent.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/);
var isAndroid 	= navigator.userAgent.match(/Android/i);
var isIPhone 	= navigator.userAgent.match(/iPhone/i);
var isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i);
var isIPad 		= navigator.userAgent.match(/iPad/i);
var isFF 		= userAgent.indexOf("firefox")!== -1;
var isEdge 		= userAgent.indexOf("applewebkit") > -1 && userAgent.indexOf("edge") > -1;
var isMobile	= true;
var isSmallScreen = false;
var isPC = true;

var $html = document.documentElement;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
	$html.classList.add("sp")
}else{
	isPC = true;
}
//win or mac
if(navigator.userAgent.indexOf("Win") !== -1 ){
	if(isFF){
		$html.classList.add("os_windows");
		$html.classList.add("firefox");
	}else{
		if(isIE){
			if(isIE11){
				$html.classList.add("ie11");
				$html.classList.add("os_windows");
				//console.log("ie11")
			}else{
				$html.classList.add("os_windows");
				$html.classList.add("legacy");
				//console.log("legacy")
			}
		}else if(isEdge){
			$html.classList.add("os_windows");
			$html.classList.add("edge");
		}else{
			$html.classList.add("os_windows");
			$html.classList.add("newbrowser");
		}
	}
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	var cls = "";
	if(isFF){
		$html.classList.add("os_mac");
		$html.classList.add("firefox");
	}else{
		if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1){
			$html.classList.add("os_mac", "safari");
		}else{
			$html.classList.add("os_mac");
		}
	}
}
var transitionend = "oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend transitionEnd";
var user = {
	category: [],
	price 	: "",
	genre 	: "",
	tags 	: []
}